.i1gnn00e{overflow:hidden;border-radius:12px;position:relative;width:156px;height:88px;}@media (max-width:600px){.i1gnn00e{height:64px;width:64px;}}
.ifk55f2{-webkit-transition:var(--transition-slow);transition:var(--transition-slow);width:100%;height:100%;object-fit:cover;}
.r6uuc5h{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;border-radius:16px;-webkit-transition:var(--transition-slow);transition:var(--transition-slow);cursor:pointer;background:var(--gsu-white);margin:0px !important;}@media screen and (min-width:600px){.r6uuc5h:hover .ifk55f2{-webkit-transform:scale(1.1);-ms-transform:scale(1.1);transform:scale(1.1);}}
.v1jouyx{padding-top:var(--element-spacing-sm);padding-bottom:var(--element-spacing-sm);border-bottom:1px solid var(--gsu-grey10);}
.iop2pss{padding-left:var(--element-padding-sm);padding-right:var(--element-padding-sm);display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-flex:1;-ms-flex:1;flex:1;}
.gfdbwd8{border-color:var(--gsu-white);}
.g16sl2q5{margin-left:var(--element-spacing-xxs);line-height:22px;color:var(--gsu-grey);}
.tl3krzz{color:var(--gsu-black80);}
.l1xefbsx{color:var(--gsu-red);display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}@media screen and (min-width:650px){.l1xefbsx{-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}}
.lkd2au6{margin-left:var(--element-spacing-xxs);}
.do8bocg{color:var(--gsu-black80);display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;}
.f1jx6dfp{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;}

.b1jr8vbu{position:absolute;top:8px;right:8px;z-index:1;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}
.bbsbg9v{margin-left:var(--element-spacing-sm);}
.i1k1qno{position:absolute;z-index:2;width:32px;height:32px;margin:auto;top:0px;left:0px;right:0px;bottom:0px;}@media screen and (min-width:600px){.i1k1qno{width:40px;height:40px;}}
.oi8h7l5{background:rgba(28,28,31,0.2);position:absolute;left:0;top:0;width:100%;height:100%;}
.fl3t772{font-size:var(--font-size-xxs);font-weight:var(--font-weight-sm);line-height:var(--line-height-sm);}@media screen and (min-width:650px){.fl3t772{margin-left:var(--element-spacing-xxxs);}}.fl3t772:first-letter{text-transform:capitalize;}
.inztwr0{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.g1cbxsjo{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-top:var(--element-padding-xxs);}@media (max-width:600px){.g1cbxsjo{margin-top:var(--element-padding-xxxs);margin-bottom:var(--element-padding-xxxs);}}
.l7glw3s{display:table-cell;vertical-align:middle;height:88px;width:88px;background:#fff2f1;border-radius:12px;text-align:center;margin-top:var(--element-spacing-sm);}
.dru7upn{text-align:center;margin-left:var(--element-spacing-sm);margin-right:var(--element-spacing-sm);width:88px;height:88px;}
.d147xlf3{height:100%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}
.h3txiow{display:none;}
.a1fq29ne{color:var(--gsu-primary);-webkit-text-decoration:underline;text-decoration:underline;}
