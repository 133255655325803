.r1med921{display:inline-block;position:relative;border-radius:50%;overflow:hidden;vertical-align:middle;background:var(--gsu-grey20);}.r1med921 img{border-radius:50%;object-fit:cover;position:absolute;top:0;bottom:0;left:0;right:0;height:inherit;width:inherit;}
.i11qtb1s{background:var(--gsu-primary);color:#fff;font-weight:bold;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;font-weight:var(--font-weight-lg);}
.oqqn94t{border:0px solid var(--gsu-tertiary10);}
.auegjlj{border-color:var(--gsu-secondary);}
.b1kchp6g{outline:6px solid var(--gsu-tertiary10);}
.xy7vra2{height:20px;width:20px;}.xy7vra2.oqqn94t{border-width:1px;}.xy7vra2.i11qtb1s{font-size:10px;}
.s1q0yox5{height:32px;width:32px;}.s1q0yox5.oqqn94t{border-width:2px;}.s1q0yox5.i11qtb1s{font-size:var(--font-size-xxxs);font-weight:400;}
.x7iqd9z{height:56px;width:56px;}.x7iqd9z.oqqn94t{border-width:3px;}.x7iqd9z.i11qtb1s{font-size:var(--font-size-lg);}
.xwoznen{height:64px;width:64px;}.xwoznen.oqqn94t{border-width:3px;}.xwoznen.i11qtb1s{font-size:var(--font-size-lg);}
.mxfnl3n{height:120px;width:120px;}.mxfnl3n.oqqn94t{border-width:3px;}.mxfnl3n.i11qtb1s{font-size:var(--font-size-lg);}
.l1p6gxjm{height:180px;width:180px;}.l1p6gxjm.oqqn94t{border-width:4px;}.l1p6gxjm.i11qtb1s{font-size:var(--font-size-xxl);}
.x13a3fe4{height:112px;width:112px;}.x13a3fe4.oqqn94t{border-width:3px;}.x13a3fe4.i11qtb1s{font-size:var(--font-size-lg);}
.x1mvn10z{height:80px;width:80px;font-size:var(--font-size-xl);}.x1mvn10z.oqqn94t{border-width:3px;}.x1mvn10z.i11qtb1s{font-size:var(--font-size-xl);}
