.r16vhfws{position:absolute;bottom:0;left:0;width:100%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;height:36px;z-index:1;}
.o103bg7i{width:8px;height:8px;background-image:radial-gradient(circle at 100% 0,transparent 0%,transparent 8px,white 8px);position:absolute;bottom:0px;right:-8px;}
.o1ou7vkb{width:8px;height:8px;background-image:radial-gradient(circle at 100% 0,transparent 0%,transparent 8px,white 8px);position:absolute;top:-8px;left:0;}
.o1kxs8md{width:10px;height:10px;background-image:radial-gradient(circle at 100% 0,transparent 0%,transparent 8px,white 8px);left:-8px;bottom:-1px;-webkit-transform:rotate(270deg);-ms-transform:rotate(270deg);transform:rotate(270deg);position:absolute;}
.s10tuerv{background:linear-gradient(180deg,rgba(28,28,31,0) 0%,rgba(28,28,31,0.7) 100%);}
.i1xr0tjl{padding:6px 8px 6px 8px;background:white;height:100%;position:relative;margin-top:1px;margin-right:-1px;}
.i1cj1and{border-radius:8px 0px 0px 0px;}
.i1wtfydr{border-radius:0px 8px 0px 0px;}
.tmmteq8{-webkit-flex:1;-ms-flex:1;flex:1;color:white;font-weight:bold;font-size:10px;padding-bottom:4px;padding-right:8px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:end;-webkit-justify-content:flex-end;-ms-flex-pack:end;justify-content:flex-end;-webkit-align-items:flex-end;-webkit-box-align:flex-end;-ms-flex-align:flex-end;align-items:flex-end;}
.i11xcp7s{width:auto;height:100%;object-fit:contain;}
