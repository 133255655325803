.w6oqifq{width:100%;height:72px;color:var(--gsu-white);position:relative;}@media screen and (max-width:980px){.w6oqifq{height:56px;}}
.n7pekod{max-height:40px;}
@media screen and (max-width:980px){.t15i9m2c{margin:0 8px;}}@media screen and (max-width:480px){.t15i9m2c{margin:0;}}
@media screen and (max-width:980px){.muxmx4z{width:56px;height:56px;display:-webkit-inline-box;display:-webkit-inline-flex;display:-ms-inline-flexbox;display:inline-flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}}
.m2txw7z{padding:0 var(--element-padding-xxs);}.m2txw7z a{height:40px;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.hxigmeg{max-width:100%;height:100%;}
.rlvh6gy{border-radius:0 0 30px 30px;}
@media screen and (min-width:980px){.l9wijlp{-webkit-align-self:flex-end;-ms-flex-item-align:end;align-self:flex-end;padding:var(--element-spacing-xxs) 0;}}
.e12b0xxx{-webkit-column-gap:var(--element-spacing-md) !important;column-gap:var(--element-spacing-md) !important;}
.a1qquqn{padding:var(--element-padding-xxs) !important;}
.c689unc{-webkit-transform:rotate(90deg);-ms-transform:rotate(90deg);transform:rotate(90deg);-webkit-align-self:center;-ms-flex-item-align:center;align-self:center;}
.s1uobh0e{position:absolute;top:0;width:100%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;z-index:3;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}
.s1bjumob{width:100%;height:100%;background:var(--gsu-primary);position:relative;padding:0.5rem 1rem;}
.s1o3a648{width:24px;height:24px;}
.s1uq5mc8{padding:1rem;}
.u147t2ml{position:fixed;background:rgba(0,0,0,0.6);top:var(--desktop-header-height);bottom:0;left:0;right:0;padding:var(--element-spacing) var(--element-spacing) var(--element-spacing) var(--desktop-nav-width);display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:flex-end;-webkit-box-align:flex-end;-ms-flex-align:flex-end;align-items:flex-end;text-align:right;-webkit-animation:overlay-u147t2ml 0.3s ease-in-out;animation:overlay-u147t2ml 0.3s ease-in-out;-webkit-backdrop-filter:blur(4px);backdrop-filter:blur(4px);}@-webkit-keyframes overlay-u147t2ml{0%{opacity:0;-webkit-backdrop-filter:blur(0px);backdrop-filter:blur(0px);}100%{opacity:1;-webkit-backdrop-filter:blur(4px);backdrop-filter:blur(4px);}}@keyframes overlay-u147t2ml{0%{opacity:0;-webkit-backdrop-filter:blur(0px);backdrop-filter:blur(0px);}100%{opacity:1;-webkit-backdrop-filter:blur(4px);backdrop-filter:blur(4px);}}@-webkit-keyframes navIn-u147t2ml{0%{opacity:0;-webkit-transform:translateY(-10px);-ms-transform:translateY(-10px);transform:translateY(-10px);}100%{opacity:1;-webkit-transform:translateY(0);-ms-transform:translateY(0);transform:translateY(0);}}@keyframes navIn-u147t2ml{0%{opacity:0;-webkit-transform:translateY(-10px);-ms-transform:translateY(-10px);transform:translateY(-10px);}100%{opacity:1;-webkit-transform:translateY(0);-ms-transform:translateY(0);transform:translateY(0);}}.u147t2ml nav{background:#fff;border-radius:var(--border-radius-lg);padding:var(--element-spacing);width:280px;-webkit-animation:navIn-u147t2ml 0.3s ease-in-out 0.3s;animation:navIn-u147t2ml 0.3s ease-in-out 0.3s;-webkit-animation-fill-mode:both;animation-fill-mode:both;}.u147t2ml ul{list-style:none;padding:0;margin:0;}.u147t2ml hr{border:none;height:1px;background:var(--gsu-dark-grey-hover);margin:var(--element-spacing) 0;}
