.r1ll1eb3{border-radius:var(--border-radius-md);cursor:pointer;padding-left:var(--element-padding-xxs);}.r1ll1eb3 i{margin-top:var(--element-spacing-xxxs);}
.pm94kxa{background-color:var(--gsu-primary);color:#fff;}
.s1gz7jwy{background-color:var(--gsu-secondary);color:#fff;}
.t1gzz2mr{background-color:var(--gsu-tertiary20);}
.t1vbclbf{background-color:var(--gsu-tertiary40);}
.wzh9guz{background-color:#fff;}
.byxej71{background-color:var(--gsu-black);color:#fff;}
.c180911c{background-color:transparent;}
.ppb43yr{background-color:var(--gsu-primary);color:#fff;}.ppb43yr .avatar span{background-color:var(--gsu-secondary);font-weight:var(--font-weight-lg);}
.t1mcezm2{white-space:nowrap;display:block;text-overflow:ellipsis;overflow:hidden;font-weight:var(--font-weight-semi-bold) !important;}
.c1u2sx79{overflow:hidden;gap:6px;}
.s1r8y94h{border-radius:40px;}.s1r8y94h .t1mcezm2{color:var(--gsu-dark-grey);font-weight:400;}
.p15ibaeu{padding:var(--element-padding-xxs);}
