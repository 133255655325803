.r1s37tlo{display:-webkit-inline-box;display:-webkit-inline-flex;display:-ms-inline-flexbox;display:inline-flex;-webkit-box-flex:0;-webkit-flex-grow:0;-ms-flex-positive:0;flex-grow:0;cursor:pointer;overflow:hidden;}
.nd8kci2{border-radius:unset !important;}
.ry59mc9{border-radius:50% !important;}
.c18sbfts{background-color:var(--gsu-secondary60);color:var(--gsu-secondary60);}
.cqpjrym{background-color:var(--gsu-green);color:var(--gsu-green);}
.e1g27jk0{background-color:var(--gsu-cat-travel);color:var(--gsu-cat-travel);}
.vzh5obk{background-color:var(--gsu-cat-creativity);color:var(--gsu-cat-creativity);}
.oxullv2{background-color:var(--gsu-cat-creativity);color:var(--gsu-cat-creativity);}
.iolq29c{color:var(--gsu-white);font-size:var(--font-size-xxxs);font-weight:var(--font-weight-semi-bold);display:-webkit-inline-box;display:-webkit-inline-flex;display:-ms-inline-flexbox;display:inline-flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;height:100%;}
.la0ukv5{height:100%;display:-webkit-inline-box;display:-webkit-inline-flex;display:-ms-inline-flexbox;display:inline-flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;background:rgba(255,255,255,0.95);}
.lx8vqvk{font-weight:var(--font-weight-semi-bold);height:100%;display:-webkit-inline-box;display:-webkit-inline-flex;display:-ms-inline-flexbox;display:inline-flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;background:rgba(255,255,255,0.95);}
.s3jq3n5{border-radius:4px;height:20px;min-width:20px;}.s3jq3n5 .iolq29c{width:20px;min-width:20px;}.s3jq3n5 .lx8vqvk{font-size:var(--font-size-xxxs);line-height:var(--font-size-md);padding:0 6px;border-radius:0 4px 4px 0;}
.sd9ukdo{border-radius:4px;height:24px;min-width:24px;}.sd9ukdo .iolq29c{width:24px;min-width:24px;}.sd9ukdo .lx8vqvk{font-size:var(--font-size-xxs);line-height:var(--font-size-xs);padding:0 8px;border-radius:0 4px 4px 0;}
.sx23dqs{border-radius:4px;height:32px;min-width:32px;}.sx23dqs .iolq29c{width:32px;min-width:32px;}.sx23dqs .lx8vqvk{font-size:var(--font-size-xs);line-height:var(--font-size-sm);padding:0 8px;border-radius:0 4px 4px 0;}
.sr3ixr1{border-radius:8px;height:40px;min-width:40px;}.sr3ixr1 .iolq29c{width:40px;min-width:40px;}.sr3ixr1 .lx8vqvk{font-size:var(--font-size-md);line-height:var(--font-size-lg);padding:0 12px;border-radius:0 8px 8px 0;}
