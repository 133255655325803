.ren0qfn{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:var(--element-spacing-sm);cursor:pointer;padding:var(--element-padding-sm) 0;border-bottom:1px solid var(--gsu-grey20);}@media screen and (min-width:600px){.ren0qfn{gap:var(--element-spacing-md);}}@media screen and (min-width:980px){.ren0qfn{-webkit-transition:background 200ms ease-out;transition:background 200ms ease-out;}.ren0qfn .heroImage{-webkit-transition:scale 300ms ease-out;transition:scale 300ms ease-out;}.ren0qfn:hover,.ren0qfn:active{background:var(--gsu-primary5);}.ren0qfn:hover .heroImage,.ren0qfn:active .heroImage{scale:1.15;}}

.i1sr70f7{overflow:hidden;height:64px;width:64px;min-width:40px;background-color:var(--gsu-dark-grey);border-radius:var(--border-radius-md);position:relative;}.i1sr70f7 .heroImage{object-fit:cover;height:100%;width:100%;}@media (min-width:600px){.i1sr70f7{height:90px;width:160px;}}
.t1tboq95{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;width:100%;}
.tkm7qg2{-webkit-box-flex:11;-webkit-flex-grow:11;-ms-flex-positive:11;flex-grow:11;-webkit-align-self:center;-ms-flex-item-align:center;align-self:center;display:grid;gap:2px;}@media (min-width:600px){.tkm7qg2{gap:4px;}}@media (min-width:980px){.tkm7qg2{gap:8px;}}
.seywtbr{color:var(--gsu-grey80);}
.gl2l0ly{margin-right:10px;}
.bsxn0ek{position:absolute;top:8px;right:8px;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}@media (max-width:980px){.bsxn0ek{top:0;right:0;gap:0;}}
.r17zg2im{margin-left:var(--element-spacing-sm);}
.noid1f5{position:absolute;top:4px;left:4px;height:40px;width:40px;z-index:3;}
