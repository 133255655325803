.rl7fn07{border-radius:var(--border-radius-md);cursor:pointer;padding:var(--element-padding-xs);}.rl7fn07 i{margin-left:var(--element-spacing-xs);margin-top:var(--element-spacing-xxxs);}
.p1kq1y4p{background-color:var(--gsu-primary);color:#fff;}
.s1lpa0g{background-color:var(--gsu-secondary);color:#fff;}
.tcxkj8d{background-color:var(--gsu-tertiary20);}
.t1k8ftsm{background-color:var(--gsu-tertiary40);}
.w1vkd1gz{background-color:#fff;}
.b1g7ifik{background-color:var(--gsu-black);color:#fff;}
.c1rvvh2w{background-color:transparent;}
.p1s5cpje{background-color:var(--gsu-primary);color:#fff;}.p1s5cpje .avatar span{background-color:var(--gsu-secondary);}
.ttbwiv3{white-space:nowrap;display:block;text-overflow:ellipsis;overflow:hidden;font-weight:var(--font-weight-md) !important;}
.cy05jlh{overflow:hidden;}
.sd8vygs{border-radius:40px;}.sd8vygs .ttbwiv3{color:var(--gsu-dark-grey);font-weight:400;}
.p18r217n{padding:var(--element-padding-xxs);}
